.navbar {
  background-color: #91203e;
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 100vw;
  box-sizing: border-box;
  overflow-x: auto;

}

.navList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  min-width: max-content;
}

.navItem {
  margin: 0 0.25rem;
}

.navLink {
  color: #ecf0f1;
  background: none;
  border: none;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  white-space: nowrap;
}

.navLink:hover {
  color: #fab438;
}

.active {
  background-color: #74102b;
}

@media (max-width: 768px) {
  .navbar {
    padding: 0.5rem 0;
  }

  .navList {
    justify-content: space-between;
    padding: 0 0.5rem;
  }

  .navItem {
    margin: 0;
  }

  .navLink {
    padding: 0.5rem 0.5rem;
    font-size: 0.9rem;
  }
}