/* ContactUs.module.css */
.contactUs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .contactInfo {
    flex: 1;
    min-width: 300px;
    padding: 1rem;
  }
  
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .formGroup {
    margin-bottom: 1rem;
  }
  
  .label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  

  .leaderInfo {
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
  }
  
  .leader {
    flex: 1;
    text-align: center;
  }
  
  .leaderImage {
    width: 30vw;
    border-radius: 50%;
    object-fit:fill;
    background-color: #9f9b9b;
    
  }
  
  .leaderName {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .leaderContact {
    font-style: italic;
  }

  @media (max-width: 768px) {
  
    .leaderImage {
      width: 25vw;
    }
     
  .leaderContact {
    font-style: italic;
    font-size: 0.7rem;
  }
  
  
  }