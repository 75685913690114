/* Home.module.css */
.home {
    text-align: center;
  }
  
  .hero {
    background-color: #791632;
    color: white;
    padding: 4rem 2rem;
    margin-bottom: 2rem;
  }
  
  .heroTitle {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .heroSubtitle {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  .logo{
    width: 10%;
    height:auto;
  }
  
  .cta {
    display: inline-block;
    background-color: #2ecc71;
    color: white;
    padding: 0.8rem 1.5rem;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .cta:hover {
    background-color: #27ae60;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 2rem 0;
  }
  
  .feature {
    flex-basis: calc(33.333% - 2rem);
    margin: 1rem;
    padding: 1.5rem;
    background-color: rgb(236, 234, 234);
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .featureIcon {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .featureTitle {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .about {
    background-color: #d3d2d2;
    padding: 3rem 2rem;
    margin-top: 2rem;
  }
  
  .aboutTitle {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 768px) {
    .feature {
      flex-basis: 75%;
    }
    .hero {
      color: white;
      padding: 1rem 1rem;
      margin-bottom: 1rem;
    }
    
    .heroTitle {
      font-size: 1.6rem;
      margin-bottom: 0.5rem;
    }
    
    .heroSubtitle {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    .logo{
      width: 50%;
      height:auto;
    }
  }