.App {
  text-align: center;
}


.App-header {
  background-color: hsl(343, 69%, 28%);
  padding: 15px 0 0 10px; 
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; 
  margin: 0 auto;
  position: relative;
}
.App-logo {
  width: 100px;
  object-fit: contain;
  position: absolute;
  bottom: 0; 
}
.App-logo:first-child {
  left: 10px; /* Adjust as needed */
}

.App-logo:last-child {
  right: 10px; /* Adjust as needed */
}
.title-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 10px;
}
.title {
  font-size: 2em;
  font-weight: bold;
  color: #f1eaec;
  text-align: center;
}
.sub-title {
  font-size: 1.4rem;
  color: #f1eaec;
  text-align: center;
}
nav {
  background-color: #333;
  padding: 10px;
}

nav button {
  background-color: transparent;
  border: none;
  color: white;
  padding: 10px 15px;
  cursor: pointer;
}

.announcements {
  background-color: #ffe6e6;
  padding: 10px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .App-header {
    padding: 15px 0 0 0;
  }
  
  .App-logo {
    width: 50px;
  }

  .App-logo:first-child {
    left: 5px;
  }

  .App-logo:last-child {
    right: 5px;
  }

  .title-section {
    padding: 15px 50px 30px 50px;
  }

  .title {
    font-size: 1em;
  }

  .sub-title {
    font-size: 0.5rem;
  }
}
