.galleryContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.galleryTitle {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: bold;
}

.featuredSection {
  position: relative;
  width: auto;
  height: 100vh;
  overflow: hidden;
  margin-bottom: 3rem;
  background-color: #f8f9fa;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.featuredImage {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.7s ease-in-out;
  border-radius: 20px;
}

.featuredImage.active {
  opacity: 1;
}

.sectionTitle {
  font-size: 2rem;
  color: #34495e;
  text-align: center;
  margin: 2rem 0 1.5rem;
  position: relative;
}

.sectionTitle::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: #91203e;
  margin: 0.5rem auto 0;
}

.galleryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
  margin: 0 15px;
}

.galleryItem {
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.galleryItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.galleryImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.galleryImage:hover {
  transform: scale(1.05);
}

.eventSelector {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 25px;
}

.eventButton {
  padding: 10px 20px;
  background-color: #ecf0f1;
  border: none;
  border-radius: 25px;
  color: #34495e;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.2s;
}

.eventButton:hover, .eventButton.active {
  background-color: #791632;
  color: white;
  transform: translateY(-2px);
}

.eventGalleryGrid {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.eventSection {
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}
.eventTitle {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  padding-bottom: 0.5rem;
}

.eventTitle::after {
  content: '';
  display: block;
  width: 70%;
  height: 2px;
  background-color: #91203e;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 768px) {
  .featuredSection {
    height: 50vh;
  }
  
  .galleryTitle {
    font-size: 2rem;
  }
  
  .sectionTitle {
    font-size: 1.5rem;
  }
  
  .eventButton {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .eventTitle {
    font-size: 1.5rem;
  }
}