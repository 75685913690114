/* AboutUs.module.css */
.aboutUs {
    max-width: 800px;
    margin: 0 auto;
    padding: 0rem 0;
  }
  
  .section {
    margin-bottom: 3rem;
  }
  
  .sectionTitle {
    font-size: 1.8rem;
    color: #2c3e50;
    border-bottom: 2px solid #3498db;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .missionStatement {
    font-style: italic;
    font-size: 1.2rem;
    color: #34495e;
    text-align: center;
    margin: 2rem 0;
  }
  
  .timeline {
    position: relative;
    padding: 1rem 0;
  }
  
  .timelineItem {
    padding: 1rem 0 1rem 2rem;
    border-left: 2px solid #3498db;
    position: relative;
    margin-bottom: 1rem;
  }
  
  .timelineItem::before {
    content: '';
    position: absolute;
    left: -0.5rem;
    top: 1.5rem;
    width: 1rem;
    height: 1rem;
    background-color: #3498db;
    border-radius: 50%;
  }
  
  .year {
    font-weight: bold;
    color: #2c3e50;
  }
  
  .values {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .value {
    flex-basis: calc(33% - 1rem);
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .valueIcon {
    font-size: 2rem;
    color: #3498db;
    margin-bottom: 0.5rem;
  }
  
  .achievements {
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 5px;
  }
  
  .achievement {
    margin-bottom: 1rem;
  }
  
  .structureInfo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .structureItem {
    flex-basis: calc(50% - 1rem);
    margin-bottom: 1rem;
  }
  
  @media (max-width: 768px) {
    .value {
      flex-basis: 100%;
    }
  
    .structureItem {
      flex-basis: 100%;
    }
    .section {
      padding: 0 1rem;
    }
  }