/* Team.module.css */
.team {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0rem 0;
  }
  
  .teamTitle {
    font-size: 2.5rem;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .teamGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .teamMember {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .teamMember:hover {
    transform: translateY(-5px);
  }
  
  .memberPhoto {
    width: 80%;
    height: 250px;
    object-fit: cover;
    background-color: #9f9b9b;
    border-radius: 10%;

  }
  
  .memberInfo {
    padding: 1.5rem;
  }
  
  .memberName {
    font-size: 1.2rem;
    color: #2c3e50;
    margin-bottom: 0.5rem;
  }
  
  .memberPosition {
    font-size: 1rem;
    color: #3498db;
    margin-bottom: 1rem;
  }
  
  .membermobileNumber {
    font-size: 0.9rem;
    color: #34495e;
    line-height: 1.6;
  }
  .structureItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 2rem 0;
  }
  
  @media (max-width: 768px) {
    .teamGrid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    .teamMember {
      margin-bottom: 1rem;
    }
  
    .memberPhoto {
      width: 70%;
      height: auto;
      object-fit: fill;
    }
  }